.max-bar {
  border-radius: 0px !important;
  //   border-right: 2px solid red;
}

.cols-2 {
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    margin-right: 5px;
  }
}

.subtitle {
  font-weight: bold;
  margin-bottom: 2px;
}

.form-list {
  & > div {
    display: flex;
    justify-content: space-between;
    label {
      //   font-weight: bold;
      margin-right: 20px;
    }

    .value {
      display: flex;

      .perc {
        width: 50px;
        text-align: right;
        font-weight: bold;
      }
    }
  }
}

.level_safe {
  color: green;
}

.level_warn {
  color: orange;
}

.level_danger {
  color: red;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 10px;

  &.level_safe {
    background-color: green;
  }

  &.level_warn {
    background-color: orange;
  }

  &.level_danger {
    background-color: red;
  }
}

.active {
  font-weight: bold;
  color: green;
}

.losing {
  font-weight: bold;
  color: orange;
}

.unactive {
  color: $dark_gray;
}
