.table-container {
  width: 100%;
  overflow: scroll;
}

table {
  margin-top: 20px;
  min-width: 100%;

  thead {
    background-color: $dark_blue;
    color: $white;
    font-weight: bold;
    margin-bottom: 5px;

    tr td {
      padding: 4px 15px;
      vertical-align: middle;
    }
  }

  tr {
    height: 35px;

    td {
      // max-width: 400px;
      line-break: anywhere;
      vertical-align: top;
      // text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 2px 5px;
      text-align: right;

      a {
        text-decoration: none;
        color: $dark_blue;
        position: relative;
      }

      time {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:nth-child(even) {
      background-color: $light_gray;
    }
  }
}
