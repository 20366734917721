html,
body {
  margin: 0;
  padding: 0;
  font-family: $font_body;
  color: $dark_blue;
  width: 100%;
}

h1 {
  font-family: $font_title;
}

h2,
h3 {
  font-family: $font_body;
}

a,
a:visited {
  color: $dark_blue;
  text-decoration: none;

  &.button {
    color: $white;

    &.link {
      color: $dark_blue;
    }
  }
}

.link {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-60 {
  margin-bottom: 60px;
}

.admin-container {
  padding: 90px;
  @media screen and (max-width: $tablet_size) {
    padding: 20px;
  }
  padding-top: 0px;
}

.btn-bar {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;

  button,
  .button {
    margin-right: 15px;
    display: inline-block;
  }

  .btn-bar-left {
    display: flex;
  }

  .btn-bar-right {
    display: flex;
  }
}

@media screen and (max-width: $mobile_size) {
  .btn-bar {
    flex-direction: column;

    .btn-bar-right {
      margin-top: 10px;
    }
  }
}

.loading {
  margin-top: 10px;
}

.container-markdown {
  padding-top: 15px;
}

.back-link,
.back-link:visited {
  color: $dark_blue;
  margin-bottom: 10px;
  display: block;
  display: inline-block;
  margin-right: 6px;
  top: 6px;
  position: relative;
}

.settings-container {
  padding: 90px;
  @media screen and (max-width: $mobile_size) {
    padding: 20px;
  }

  padding-top: 0px;

  h2 {
    margin-top: 40px;
  }
}

.content-center {
  width: 100%;
  text-align: center;
}

.content-table {
  max-width: 100%;
  overflow: scroll;
}

.meta {
  font-size: 11px;
  color: $gray;
}
